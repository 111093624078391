import { IDeliveryBase, IDeliveryDestination } from 'shared/models/bases.model';
import { AuthHelper } from 'utils/authHelper.util';
import { IUserBasisSettings } from 'models/userBasisSettings.model';
import { IDeliveryCalendarRequest } from 'models/services/api/deliveryCalendar.model';

export class BasesApiService {
  static async getUserBases(): Promise<Array<IDeliveryBase>> {
    return await AuthHelper.fetch<{ bases }>('/api/v1/clients/basis').then((res) => res.bases);
  }

  static async getBaseById(id: number): Promise<IDeliveryBase> {
    return await AuthHelper.fetch(`/api/v1/bases/${id}`).then((res) => res?.basesInfo);
  }

  static async getBaseByFiasId(fiasId: string): Promise<IDeliveryBase> {
    return await AuthHelper.fetch('/api/v1/bases', { method: 'GET', params: { fias_id: fiasId } }).then((res) => res.basesInfo);
  }

  static async getDeliveryDestinationsByBasesId(basesId: number): Promise<Array<IDeliveryDestination>> {
    return await AuthHelper.fetch(`/api/v1/bases/${basesId}/delivery_destinations`, { method: 'GET' });
  }

  public static async getDeliveryCalendar(calendarParams: IDeliveryCalendarRequest): Promise<Array<string>> {
    return await AuthHelper.fetch('/api/v1/delivery/calendar', {
      method: 'POST',
      body: calendarParams,
    });
  }

  public static async getUserBasisSettings(): Promise<IUserBasisSettings> {
    return await AuthHelper.fetch('/api/v1/user_basis_settings');
  }

  public static async setUserBasisSettings(body: Omit<IUserBasisSettings, 'userId'>): Promise<IUserBasisSettings> {
    return await AuthHelper.fetch('/api/v1/user_basis_settings', {
      method: 'POST',
      body,
    });
  }
}
