import * as Yup from 'yup';
import { EValidatorsMessages } from '../enums/validators.enum';
import { Ref } from 'vue';
import { ControlSumChecker } from './controlSumCheck.util';
import { UrlHelper } from './url.util';
import { IFile } from '../models/files.model';
import { FileTemp } from '../services/fileTemp.service';
import * as dateFns from 'date-fns';
import { ERuLocales } from '../enums/ruLocales.enum';
import { StringsHelper } from '@maksmart/maksify/dist/utils';

export class Validators {
  public static required(message = EValidatorsMessages.Required) {
    return (value?: string | boolean) => (value != null && Boolean(String(value).trim())) || message;
  }

  public static checked(message = EValidatorsMessages.Required) {
    return (value?: boolean) => !!value || message;
  }

  public static min(min: number, message = EValidatorsMessages.Min.replace('{min}', min.toString())) {
    return (value?: string) => {
      const numberValue = StringsHelper.tryToNumber(value as string);
      return (typeof numberValue === 'number' && numberValue > min) || message;
    };
  }

  public static max(max: number, message = EValidatorsMessages.Max.replace('{max}', max.toString())) {
    return (value?: string) => {
      const numberValue = StringsHelper.tryToNumber(value as string);
      return (typeof numberValue === 'number' && numberValue < max) || message;
    };
  }

  public static maxIncluded(max: number, message = EValidatorsMessages.Max.replace('{max}', max.toString())) {
    return (value?: string) => {
      const numberValue = StringsHelper.tryToNumber(value as string);
      return (typeof numberValue === 'number' && numberValue <= max) || message;
    };
  }

  public static onlyNumbers(message = EValidatorsMessages.OnlyNumbers, replaceSemi = false) {
    return (value?: string) => {
      if (!value) {
        return true;
      }

      const internalValue = replaceSemi ? value.replace(',', '.') : value;

      if (!isNaN(Number(internalValue))) {
        return true;
      }

      return message;
    };
  }

  public static integer(message = EValidatorsMessages.Integer) {
    return (value?: string) => !value || Number(value) % 1 === 0 || message;
  }

  public static maxLength(maxLength: number, message = EValidatorsMessages.MaxLength.replace('{maxLength}', maxLength.toString())) {
    return (value?: string) => !value || value.length <= maxLength || message;
  }

  public static minLength(minLength: number, message = EValidatorsMessages.MinLength.replace('{minLength}', minLength.toString())) {
    return (value?: string) => (value && value.length >= minLength) || message;
  }

  public static neededLengths(
    lengths: Array<number>,
    message = EValidatorsMessages.NeededLengths.replace('{neededLength}', lengths.join(' или ')),
  ) {
    return (value?: string) => (value != null && lengths.some((length) => String(value).length === length)) || message;
  }

  public static yupEmail(message = EValidatorsMessages.Email) {
    return (value?: string) => Yup.string().email().isValidSync(value) || message;
  }

  public static inn(message = EValidatorsMessages.Inn) {
    return (value?: string) => ControlSumChecker.checkInn(value) || message;
  }

  public static ogrn(message = EValidatorsMessages.Ogrn) {
    return (value?: string) => ControlSumChecker.checkOgrn(value) || message;
  }

  public static url(message = EValidatorsMessages.Url) {
    return (value?: string) => !value || UrlHelper.isValidUrl(value) || message;
  }

  public static phoneNumber(message = EValidatorsMessages.PhoneNumber) {
    const regExp = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{10}$/;
    return (value?: string) => (value != null && regExp.test(String(value))) || message;
  }

  public static notRequiredPhoneNumber(message = EValidatorsMessages.PhoneNumber) {
    const regExp = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{10}$/;
    return (value?: string) => (!value ? true : regExp.test(String(value)) || message);
  }

  public static isEqual(value: Ref<string>, message = EValidatorsMessages.IsEqual) {
    return (comparable?: string) => {
      return (value.value != null && comparable === value.value) || message;
    };
  }

  public static password(message = EValidatorsMessages.Password) {
    const regExp = /(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9])(?=.*[!"№;%:?*()_+\-=#$%^&]).*/g;
    return (value?: string) => String(value).match(regExp) || message;
  }

  public static russianString(message = EValidatorsMessages.RussianString) {
    const regExp = /[^ёЁ\-А-Яа-я\s]+/g;
    return (value?: string) => !String(value).match(regExp) || message;
  }

  /**
   * Данный валидатор необходимо использовать только для валидации полей с файлами!
   * Метод проверяет обязательное наличие хотя бы одного файла с расширением, которое находится в переданном массиве расширений.
   * Например при загрузке файла с электронной подписью, загружается основной файл и вспомогательные файлы с расширениями
   * .xml и .sig. Вспомогательные файлы игнорируются и проверяется обязательное наличие основного файла по расширению, например .pdf.
   * @param fileExtensions - массив расширений в виде ['.png', '.pdf', '.jpg']
   * @param message - сообщение об ошибке, если валидация не прошла
   */
  public static requiredOneOfFileExtensions(fileExtensions: Array<string>, message = EValidatorsMessages.Required) {
    return (files?: Array<IFile> | Array<FileTemp>) =>
      files?.some((file) =>
        fileExtensions.some((fileExtension) =>
          file instanceof FileTemp
            ? file?.file instanceof File
              ? file.file.name.toLowerCase?.()?.endsWith?.(fileExtension)
              : file?.file?.fileName?.toLowerCase?.()?.endsWith?.(fileExtension)
            : file?.fileName?.toLowerCase?.()?.endsWith?.(fileExtension),
        ),
      ) || message;
  }

  public static maxDate(maxDate?: Date, message = EValidatorsMessages.Date as string, includingMax = true) {
    return (value: Date | string) => {
      let internalValue: string | Date = value;
      if (!maxDate || !value || !(maxDate instanceof Date)) {
        return true;
      }

      if (typeof internalValue === 'string') {
        internalValue = new Date(value);
      }

      const formattedValue = internalValue.setHours(0, 0, 0, 0);
      const formattedMaxDate = maxDate.setHours(0, 0, 0, 0);
      const dateIsBefore = dateFns.isBefore(formattedValue, formattedMaxDate);

      return (includingMax ? dateIsBefore || dateFns.isEqual(formattedValue, formattedMaxDate) : dateIsBefore) || message;
    };
  }

  public static todayMinDate(message = EValidatorsMessages.SupplierMinDateToday) {
    return (value: string | Date) => {
      let internalValue: string | Date = value;

      if (!value) {
        return true;
      }

      if (typeof internalValue === 'string') {
        internalValue = new Date(internalValue);
      }

      const today = new Date(Date.now());
      today.setHours(0, 0, 0, 0);
      return dateFns.isBefore(internalValue, today) ? message : true;
    };
  }

  public static maxDigitsAfterComma(maxDigits = 2, message = EValidatorsMessages.MaxDigitsAffterComma, i18n = useI18n()) {
    const { t } = i18n;
    const regExp = new RegExp(`^\\d+(,\\d{0,${maxDigits}})?$`);
    const internalMessage = message.replace('{maxDigits}', t(ERuLocales.Decimals, maxDigits));

    return (value: string) => !value || regExp.test(value.replace('.', ',')) || internalMessage;
  }

  public static customRegExp(regexp: RegExp, message = EValidatorsMessages.NotValidString) {
    return (value: string) => !value || regexp.test(value) || message;
  }
}
