import { Ref } from 'vue';
import { EFilterTypes } from '../enums/filters.enum'; // todo fix import from other module
import {
  IFilter,
  IFilterBoolean,
  IFilterDateRange,
  IFilterEnum,
  IFilterEnumOption,
  IFilterItem,
  IFilterRange,
  IFilterSort,
  IFilterResponse,
  IFiltersMakeQueryParams,
  IFilterText,
  IFilterRadio,
  TQueryFilters,
} from '../models/filters.model';
import { DateFormat } from './dateFormat.util';
import { uniqBy } from 'lodash-es';
import { RoundHelper } from './roundHelper.util';
import { LocationQueryValue } from '@nuxtjs/i18n/node_modules/@intlify/vue-router-bridge';
import { cloneDeep } from 'lodash-es';
import { EDateRangeFilterKey } from 'shared/enums/dateRangeFilterKey.enum';

export class FilterUtils {
  static readonly mapFilterResetFunctions = new Map<EFilterTypes, (filter: IFilter, originalFilter: IFilter) => string>([
    [EFilterTypes.Enum, FilterUtils.getEnumFilterText],
    [EFilterTypes.Bool, FilterUtils.getBoolFilterText],
    [EFilterTypes.Text, FilterUtils.getTextFilterText],
    [EFilterTypes.Range, FilterUtils.getRangeFilterText],
    [EFilterTypes.DateRange, FilterUtils.getDateRangeText],
    [EFilterTypes.Sort, FilterUtils.getSortFilterText],
    [EFilterTypes.Radio, FilterUtils.getRadioFilterText],
  ]);

  static readonly mapFilterGetValueFunctions = new Map<
    EFilterTypes,
    (filterItem: IFilterItem, params?: IFiltersMakeQueryParams) => Record<string, Array<string | number | boolean>>
  >([
    [EFilterTypes.Bool, FilterUtils.getBoolFilterValue],
    [EFilterTypes.Text, FilterUtils.getTextFilterValue],
    [EFilterTypes.Enum, FilterUtils.getEnumFilterValue],
    [EFilterTypes.Range, FilterUtils.getRangeFilterValue],
    [EFilterTypes.DateRange, FilterUtils.getDateRangeFilterValue],
    [EFilterTypes.Sort, FilterUtils.getSortFilterValue],
    [EFilterTypes.Radio, FilterUtils.getRadioFilterValue],
  ]);

  static readonly mapFilterIsValueFunctions = new Map<EFilterTypes, (filter: IFilter) => boolean>([
    [EFilterTypes.Bool, FilterUtils.isBoolFilterValue],
    [EFilterTypes.Enum, FilterUtils.isEnumFilterValue],
    [EFilterTypes.Range, FilterUtils.isRangeFilterValue],
    [EFilterTypes.DateRange, FilterUtils.isDateRangeFilterValue],
    [EFilterTypes.Radio, FilterUtils.isRadioFilterValue],
  ]);

  private static readonly filterValueFromQuerySetterFunctionsMap = new Map<
    EFilterTypes,
    (filter: IFilterItem, queryFilters: TQueryFilters) => IFilterItem
  >([
    [EFilterTypes.Enum, FilterUtils.setEnumFilterValueFromQuery],
    [EFilterTypes.Bool, FilterUtils.setBoolFilterValueFromQuery],
    [EFilterTypes.Range, FilterUtils.setRangeFilterValueFromQuery],
    [EFilterTypes.DateRange, FilterUtils.setDateRangeFilterValueFromQuery],
    [EFilterTypes.Radio, FilterUtils.setRadioFilterValueFromQuery],
  ]);

  public static getCombinedFilters(
    currentFiltersItems: Array<IFilterItem> = [],
    incomingFilters: Array<IFilter> = [],
    prevFilterResponse?: IFilterResponse,
  ): { currentFiltersItems: Array<IFilterItem>; filters: Array<IFilter> } {
    const selectedFilters = this.getSelectedFilters(currentFiltersItems) ?? [];

    incomingFilters.forEach((filter) => {
      if (this.isRangeFilter(filter)) {
        const prevFilter: IFilterRange | undefined = prevFilterResponse?.items?.find((prevFilter) => prevFilter.id === filter.id);
        filter.minValue = +RoundHelper.roundWithPrecision(prevFilter?.minValue ?? filter.minValue, false);
        filter.maxValue = +RoundHelper.roundWithPrecision(prevFilter?.maxValue ?? filter.maxValue, true);
        return;
      }
    });

    const filtersFromCurrent = currentFiltersItems.filter((currentFilter) => {
      const foundInIncomingFilters = !!incomingFilters.find((filter) => filter?.id === currentFilter?.filter?.id);
      const foundInSelectedFilters = !!selectedFilters.find((filter) => filter?.filter?.id === currentFilter?.filter?.id);
      return foundInIncomingFilters || foundInSelectedFilters;
    });

    const filtersFromIncoming = this.mapFiltersItems(selectedFilters, incomingFilters);
    currentFiltersItems = uniqBy([...filtersFromIncoming, ...filtersFromCurrent], (filter: IFilterItem) => filter?.filter?.id);

    return { currentFiltersItems, filters: currentFiltersItems.map((filterItem) => filterItem.filter) };
  }

  public static getFilterActiveText(filter: IFilter, originalFilter: IFilter, ...args: Array<unknown>): string {
    if (filter) {
      return this.mapFilterResetFunctions.get(filter.type)?.call(this, filter, originalFilter, ...args) ?? '';
    }
    return '';
  }

  static getFilterValue(filterItem: IFilterItem, params?: IFiltersMakeQueryParams): Record<string, Array<string | number | boolean>> {
    if (!filterItem) {
      return {};
    }

    return this.mapFilterGetValueFunctions.get(filterItem.filter.type)?.call(this, filterItem, params) ?? {};
  }

  static isFilterValue(filter: IFilter): boolean {
    if (!filter) {
      return false;
    }

    return this.mapFilterIsValueFunctions.get(filter.type)?.call(this, filter);
  }

  public static isRangeFilter(filter: IFilter): filter is IFilterRange {
    return filter.type === EFilterTypes.Range;
  }

  public static isBooleanFilter(filter: IFilter): filter is IFilterBoolean {
    return filter.type === EFilterTypes.Bool;
  }

  public static isTextFilter(filter: IFilter): filter is IFilterText {
    return filter.type === EFilterTypes.Text;
  }

  public static isEnumFilter(filter: IFilter): filter is IFilterEnum {
    return filter.type === EFilterTypes.Enum;
  }

  public static isSortFilter(filter: IFilter): filter is IFilterSort {
    return filter.type === EFilterTypes.Sort;
  }

  public static isRadioFilter(filter: IFilter): filter is IFilterRadio {
    return filter.type === EFilterTypes.Radio;
  }

  public static isDateRangeFilter(filter: IFilter): filter is IFilterDateRange {
    return filter.type === EFilterTypes.DateRange;
  }

  private static getEnumFilterText(filter: IFilter, _: IFilter, getName = false, getNameWithCount = false): string {
    if (!this.isEnumFilter(filter)) {
      return '';
    }

    const checkedCount = filter.options?.filter((option) => option.checked)?.length;
    if (checkedCount === 1) {
      return filter.options?.find((option) => option.checked)?.name || '';
    }

    if (getNameWithCount) {
      return this.getEnumFilterLabelsWithCount(filter);
    }

    if (getName) {
      return this.getEnumFilterLabels(filter);
    }

    return !checkedCount ? '' : checkedCount.toString();
  }

  private static getSortFilterText(filter: IFilter): string {
    if (!this.isSortFilter(filter)) {
      return '';
    }
    return filter.name;
  }

  private static getRadioFilterText(filter: IFilter): string {
    if (!this.isRadioFilter(filter)) {
      return '';
    }

    return filter.options?.find((option) => option?.id === filter.value)?.name ?? '';
  }

  private static getEnumFilterLabelsWithCount(filter: IFilterEnum): string {
    const text = filter.options?.filter((option) => option.checked)?.length;

    if (!text) {
      return '';
    }

    return text >= 2 ? text.toString() : this.getEnumFilterLabels(filter);
  }

  private static getEnumFilterLabels(filter: IFilterEnum): string {
    return filter.options
      .filter((option) => option.checked)
      .map((option) => option.name)
      .join(', ');
  }

  private static getBoolFilterText(filter: IFilter): string {
    if (this.isBooleanFilter(filter)) {
      return filter.value ? null : '';
    }
    return '';
  }

  private static getTextFilterText(filter: IFilter): string {
    return this.isTextFilter(filter) && filter.value ? filter.value : '';
  }

  private static getRangeFilterText(filter: IFilterRange, originalFilter: IFilterRange): string {
    if (!this.isRangeFilter(filter)
      || (filter.minValue === originalFilter.minValue
          && filter.maxValue === originalFilter.maxValue
        )
      || (filter.minValue < 0 && filter.maxValue < 0)
    ) {
      return '';
    }

    return `${filter.minValue} - ${filter.maxValue}`;
  }

  private static getDateRangeText(filter: IFilter, originalFilter: IFilterDateRange): string {
    if (!this.isDateRangeFilter(filter)) {
      return '';
    }

    if (filter.dateFrom === originalFilter.dateFrom
      && filter.dateTo === originalFilter.dateTo
    ) {
      return '';
    }

    if (filter.dateFrom && filter.dateTo) {
      return `${DateFormat.default(filter.dateFrom)} - ${DateFormat.default(filter.dateTo)}`;
    }

    if (filter.dateFrom && !filter.dateTo) {
      return `от ${DateFormat.default(filter.dateFrom)}`;
    }

    if (!filter.dateFrom && filter.dateTo) {
      return `до ${DateFormat.default(filter.dateTo)}`;
    }


    return '';
  }

  private static calculateEnumCount(array: Array<IFilterEnumOption>): string {
    return array.reduce((sum, { count, checked }) => (checked ? sum + count : sum), 0).toString();
  }

  private static getBoolFilterValue(filterItem: IFilterItem): Record<string, Array<boolean>> {
    if (!this.isBooleanFilter(filterItem?.filterState)
      || !filterItem.filterState.value
    ) {
      return {};
    }

    return {
      [filterItem.filter.id]: [true],
    };
  }

  private static getTextFilterValue(filterItem: IFilterItem): Record<string, string> {
    return this.isTextFilter(filterItem?.filterState)
      ? { [filterItem.filter?.id]: filterItem.filterState.value }
      : {};
  }

  private static getEnumFilterValue(filterItem: IFilterItem): Record<string, Array<number | string> | string> {
    if (!this.isEnumFilter(filterItem?.filterState)) {
      return {};
    }

    const checkedOptions = filterItem.filterState.options.filter((option) => option.checked).map((option) => option.id);

    if (!checkedOptions?.length) {
      return {};
    }

    return {
      [filterItem.filter.id]: checkedOptions,
    };
  }

  private static getRangeFilterValue(filterItem: IFilterItem): Record<string, Array<number>> {
    if (!this.isRangeFilter(filterItem?.filterState)) {
      return {};
    }

    const { filterState, filter } = filterItem;

    if (filterState.minValue === (filter as IFilterRange).minValue
      && filterState.maxValue === (filter as IFilterRange).maxValue
    ) {
      return {};
    }

    return {
      [filterItem.filter?.id]: [filterItem.filterState.minValue, filterItem.filterState.maxValue],
    };
  }

  private static getDateRangeFilterValue(filterItem: IFilterItem, params?: IFiltersMakeQueryParams): Record<string, Array<string> | string | undefined> | undefined {
    if (!this.isDateRangeFilter(filterItem?.filterState)) {
      return {};
    }

    const { filterState, filter } = filterItem;

    if (filterState.dateFrom === (filter as IFilterDateRange).dateFrom
      && filterState.dateTo === (filter as IFilterDateRange).dateTo
    ) {
      return {};
    }

    if (params?.dateRangeSeparate || params?.dateRangeGteLte) {
      const dateFrom = filterItem.filterState?.dateFrom;
      const dateTo = filterItem.filterState?.dateTo;
      const formattedDateFrom = dateFrom ? DateFormat.iso(dateFrom) : undefined;
      const formattedDateTo = dateTo ? DateFormat.iso(dateTo) : undefined;

      if (!formattedDateTo && !formattedDateFrom) {
        return;
      }

      return params?.dateRangeGteLte
        ? {
          [filterItem.filter?.id]: {
            gte: formattedDateFrom,
            lte: formattedDateTo,
          },
        } : {
          dateFrom: formattedDateFrom,
          dateTo: formattedDateTo,
        };
    }

    return {
      [filterItem.filter?.id]: [filterItem.filterState.dateFrom, filterItem.filterState.dateTo],
    };
  }

  private static getSortFilterValue(filterItem: IFilterItem, params?: IFiltersMakeQueryParams): Record<string, string> {
    if (!this.isSortFilter(filterItem.filter)) {
      return {};
    }

    const value = params?.sortFromSelectedValue
      ? filterItem.filterState?.selectedValue?.id
      : filterItem.filterState?.id;

    return {
      [filterItem.filter?.id]: params?.sortToArray ? [value] : value,
    };
  }

  private static getRadioFilterValue(filterItem: IFilterItem): Record<string, boolean | string> {
    if (!this.isRadioFilter(filterItem.filter)) {
      return {};
    }

    return {
      [filterItem?.filter?.id]: filterItem.filterState?.value,
    };
  }

  static makeQueryObject(filtersItems: Array<IFilterItem>, params?: IFiltersMakeQueryParams): Record<string | number, string | number | Array<number | string>> {
    return filtersItems?.reduce((filters, filterItem) => {
      if (!filterItem?.filterState) {
        return filters;
      }

      return {
        ...filters,
        ...this.getFilterValue(filterItem, params),
      };
    }, {});
  }

  static makeQueryObjectString(filtersItems: Array<IFilterItem>, params?: IFiltersMakeQueryParams): string {
    return JSON.stringify(this.makeQueryObject(filtersItems, params));
  }

  static clearAllFilters(filtersItems: Ref<Array<IFilterItem>>): void {
    filtersItems?.value?.forEach((filterItem) => {
      if (filterItem.filterState) {
        filterItem.filterState = filterItem.filter;
      }
    });
  }

  static getSelectedFilters(filtersItems: Array<IFilterItem>): Array<IFilterItem> {
    return filtersItems?.filter(
      (filterItem) => (!!filterItem.filterState && (
          this.getFilterActiveText(filterItem.filterState, filterItem.filter) !== '' ||
          (filterItem.filterState.type && this.isBooleanFilter(filterItem.filterState) && filterItem.filterState.value)
        )
      ),
    );
  }

  static mapFiltersItems(filtersItems: Array<IFilterItem>, filters: Array<IFilter>): Array<IFilterItem> {
    return filters?.map((filter) => ({
      filter,
      filterState: filtersItems?.find((filterItem) => filterItem.filter?.id === filter.id)?.filterState || filter,
      baseFilterProps: filter.baseFilterProps,
    }));
  }

  private static isEnumFilterValue(filter: IFilter): boolean {
    if (!this.isEnumFilter(filter)) {
      return false;
    }

    return !!filter.options?.filter((option) => option.checked)?.length;
  }

  private static isBoolFilterValue(filter: IFilter): boolean {
    if (!this.isBooleanFilter(filter)) {
      return false;
    }

    return filter.value;
  }

  private static isRangeFilterValue(filter: IFilter): boolean {
    if (!this.isRangeFilter(filter)) {
      return false;
    }
    return !!(filter.minValue && filter.maxValue);
  }

  private static isDateRangeFilterValue(filter: IFilter): boolean {
    if (!this.isDateRangeFilter(filter)) {
      return false;
    }

    return !!(filter.dateFrom && filter.dateTo);
  }

  private static isRadioFilterValue(filter: IFilter): boolean {
    if (!this.isRadioFilter(filter)) {
      return false;
    }

    return filter.value !== undefined;
  }

  public static getFilterValueById<T>(
    filterItems: Array<IFilterItem>,
    id: string,
    params?: IFiltersMakeQueryParams,
  ): T | undefined {
    const foundFilterItem = filterItems?.find((filterItem) => filterItem.filter.id === id);

    if (!foundFilterItem) {
      return;
    }

    foundFilterItem.filterState = unref(foundFilterItem.filterState);

    return FilterUtils.getFilterValue(foundFilterItem, params)?.[id];
  }

  public static setFilterValuesFromQuery(
    filterItems: Array<IFilterItem>,
    queryFilters: LocationQueryValue | LocationQueryValue[] | TQueryFilters,
  ): Array<IFilterItem>{
    if (!filterItems?.length
      || !queryFilters
    ) {
      return filterItems || [];
    }

    const preparedQueryFilters = typeof queryFilters === 'string'
      ? JSON.parse(queryFilters)
      : queryFilters;

    return filterItems.map(
      (filterItem) => FilterUtils.filterValueFromQuerySetterFunctionsMap.has(filterItem.filter.type)
        ? FilterUtils.filterValueFromQuerySetterFunctionsMap.get(filterItem.filter.type)?.(filterItem, preparedQueryFilters) || filterItem
        : filterItem,
    );
  }

  private static setEnumFilterValueFromQuery(
    filterItem: IFilterItem,
    queryFilters: TQueryFilters,
  ): IFilterItem<IFilterEnum | IFilter> {
    if (!FilterUtils.isEnumFilter(filterItem.filter)
      || !FilterUtils.isQueryFiltersHasFilter(filterItem.filter, queryFilters)
    ) {
      return filterItem;
    }

    const filterState = filterItem.filterState
      || cloneDeep(filterItem.filter);

    return {
      ...filterItem,
      filterState: {
        ...filterState,
        options: (filterState as IFilterEnum).options.map(
          (option) => ({
            ...option,
            checked: (queryFilters
              ?.[filterItem.filter.id as keyof TQueryFilters] as Array<string | number>)
              ?.includes(option.id),
          }),
        ),
      },
    };
  }

  private static setBoolFilterValueFromQuery(
    filterItem: IFilterItem,
    queryFilters: TQueryFilters,
  ): IFilterItem<IFilterBoolean | IFilter> {
    if (!FilterUtils.isBooleanFilter(filterItem.filter)
      || !FilterUtils.isQueryFiltersHasFilter(filterItem.filter, queryFilters)
    ) {
      return filterItem;
    }

    const filterState = filterItem.filterState
      || cloneDeep(filterItem.filter);

    const queryFilterKey = filterItem.filter.id as keyof TQueryFilters;

    return {
      ...filterItem,
      filterState: {
        ...filterState,
        value: Boolean(
          Array.isArray(queryFilters?.[queryFilterKey])
            ? queryFilters?.[queryFilterKey]?.[0]
            : queryFilters?.[queryFilterKey],
        ),
      },
    };
  }

  private static setRangeFilterValueFromQuery(
    filterItem: IFilterItem,
    queryFilters: TQueryFilters,
  ): IFilterItem<IFilterRange | IFilter>{
    if (!FilterUtils.isRangeFilter(filterItem.filter)
      || !FilterUtils.isQueryFiltersHasFilter(filterItem.filter, queryFilters)
    ) {
      return filterItem;
    }

    const filterState = filterItem.filterState
      || cloneDeep(filterItem.filter);

    const queryFilterKey = filterItem.filter.id as keyof TQueryFilters;

    return {
      ...filterItem,
      filterState: {
        ...filterState,
        minValue: Number(queryFilters?.[queryFilterKey]?.[0]),
        maxValue: Number(queryFilters?.[queryFilterKey]?.[1]),
      },
    };
  }

  private static setDateRangeFilterValueFromQuery(
    filterItem: IFilterItem,
    queryFilters: TQueryFilters,
  ): IFilterItem<IFilterDateRange | IFilter> {
    if (!FilterUtils.isDateRangeFilter(filterItem.filter)
      || !FilterUtils.isQueryHasDateRangeFilter(queryFilters)
    ) {
      return filterItem;
    }

    const filterState = (filterItem.filterState
      || cloneDeep(filterItem.filter)) as IFilterDateRange;

    const queryFilterKey = filterItem.filter.id as keyof TQueryFilters;

    return {
      ...filterItem,
      filterState: {
        ...filterState,
        dateFrom: queryFilters?.['dateFrom']?.toString()
          || queryFilters?.[queryFilterKey]?.[0]?.toString()
          || '',
        dateTo: queryFilters?.['dateTo']?.toString()
          || queryFilters?.[queryFilterKey]?.[1]?.toString()
          || '',
      },
    };
  }

  private static setRadioFilterValueFromQuery(
    filterItem: IFilterItem,
    queryFilters: TQueryFilters,
  ): IFilterItem<IFilterRadio | IFilter>{
    if (!FilterUtils.isRadioFilter(filterItem.filter)
      || !FilterUtils.isQueryFiltersHasFilter(filterItem.filter, queryFilters)
    ) {
      return filterItem;
    }

    const filterState = (filterItem.filterState
      || cloneDeep(filterItem.filter)) as IFilterRadio;

    return {
      ...filterItem,
      filterState: {
        ...filterState,
        value: (filterState.options?.find(
          (option) => option.id === queryFilters[filterItem.filter.id as keyof TQueryFilters])
        )?.id,
      },
    };
  }

  private static isQueryFiltersHasFilter(filter: IFilter, queryFilters: TQueryFilters): boolean {
    return typeof queryFilters?.[filter?.id as keyof TQueryFilters] !== 'undefined';
  }

  private static isQueryHasDateRangeFilter(queryFilters: TQueryFilters): boolean {
    return typeof queryFilters?.[EDateRangeFilterKey.DateFrom] !== 'undefined' || typeof queryFilters?.[EDateRangeFilterKey.DateTo] !== 'undefined';
  }

  public static getFilterById(filterItems: Array<IFilterItem>, id: string): IFilterItem | undefined {
    return filterItems.find((filterItem) => filterItem.filter.id === id);
  }
}
