export enum EUserGroup {
  /** Администратор */
  ClientAdmin = 'client_admin',
  /** Покупатель ЕРП */
  ClientErp = 'client_erp',
  /** Покупатель ЕРП МП */
  ClientMpErp = 'client_mp_erp',
  /** Аудитор */
  ClientAuditor = 'client_auditor',
  /** Финансовый контролер */
  ClientFinContr = 'client_fin_contr',
  /** Руководитель */
  ClientBoss = 'client_boss',
  /** Категорийный менеджер */
  ClientCategoryManager = 'client_category_m',
  /** Управляющий МП */
  ClientMpSupervisor = 'client_mp_supervisor',
  /** Поставщик менеджер */
  Supplier = 'supplier',
  /** Юрист */
  MpLawyer = 'mp_lawyer',
  /** Администратор контента */
  MpContentAdmin = 'mp_content_admin',
  /** Администратор МП */
  MpAdmin = 'mp_admin',
  /** Фрилансер МП */
  MpContentFreelancer = 'mp_content_freelancer',
}
