import { IDeliveryBase } from 'shared/models/bases.model';
import { IClientQueryParams, IClientsGetResponse } from 'models/client.model';
import { IPaymentInfoResponse } from 'models/client/payment.model';
import { AuthHelper } from 'utils/authHelper.util';
import { IUserDeputy, IUserInfoExtended, IUserInfoResponse } from 'models/auth/userInfo.model';
import { IClientResponse, IClientShortInfo } from 'models/client/client.model';
import { IUserSearchInfo } from 'models/client/userSearchInfo.model';
import { useUserStore } from 'store/user.store';
import { EUserGroup } from 'shared/enums/user/userGroup.enum';
import { ICreateClientPFM, IClientPFMItem, IClientPFMTableItem, IEditClientPFM } from 'models/client/pfm.model';
import { IUserBusinessEntities } from 'models/client/employee.model';
import { ISubdivisionInfoResponse } from 'models/client/subdivisions.model';
import { IAddEmployee, IUserBusinessEntities, IEmployeesList } from 'models/client/employee.model';
import { IBusinessEntityByUser } from 'models/client/businessEntities.model';

export class ClientApi {
  public static async getClientId(): Promise<number | undefined> {
    return (await AuthHelper.fetch<IClientResponse>('/api/v1/clients/current', { method: 'GET' }))?.id;
  }

  public static async getCurrentClient(): Promise<IPaymentInfoResponse> {
    return await AuthHelper.fetch<IPaymentInfoResponse>('/api/v1/clients/current/valid_entities');
  }

  public static async getCurrentProfile(): Promise<IUserInfoExtended> {
    return await AuthHelper.fetch<IUserInfoExtended>('/api/v1/clients/current/profile');
  }

  public static async getBases(clientId: number): Promise<Array<IDeliveryBase>> {
    return (await AuthHelper.fetch<{ bases: Array<IDeliveryBase> }>(`/api/v1/clients/${clientId}/bases_select`, { params: { mpOrdersAllowed: true }}))?.bases;
  }

  public static async getClients(params?: IClientQueryParams): Promise<IClientsGetResponse> {
    return await AuthHelper.fetch<IClientsGetResponse>('/api/v1/clients/list', {
      params,
    });
  }

  public static async getClientDeputies(sub: string): Promise<Array<IUserDeputy>> {
    return await AuthHelper.fetch('/api/v1/deputies', { params: { delegatorId: sub } });
  }

  public static async addClientDeputy(deputySub: string): Promise<void> {
    return AuthHelper.fetch('/api/v1/deputies', { method: 'POST', body: { delegatee_id: deputySub } });
  }

  public static async deleteClientDeputy(deputySub: string): Promise<void> {
    return AuthHelper.fetch('/api/v1/deputies', { method: 'DELETE', body: { delegatee_id: deputySub } });
  }

  public static async searchClientDelegatees(q: string): Promise<Array<IUserInfoResponse>> {
    return await AuthHelper.fetch<Array<IUserInfoResponse>>('/api/v1/users/search', { method: 'POST', params: { q } });
  }

  public static async searchClientApprovers(q?: string, beId?: number): Promise<Array<IUserSearchInfo>> {
    return await AuthHelper.fetch<Array<IUserSearchInfo>>('/api/v1/users/search_boss', {
      method: 'POST',
      params: { q },
      body: { be_id: beId },
    });
  }

  public static async searchClientRecipient(q: string): Promise<Array<IUserInfoResponse>> {
    return await AuthHelper.fetch<Array<IUserInfoResponse>>('/api/v1/users/search', {
      method: 'POST',
      params: { q },
      body: {
        sub: useUserStore()?.userInfo?.sub,
        roles: [
          EUserGroup.ClientErp,
          EUserGroup.ClientMpErp,
          EUserGroup.ClientAdmin,
          EUserGroup.ClientMpSupervisor,
        ],
      },
    });
  }

  public static async searchClientRecipentWithBeId(q: string, beId: number): Promise<Array<IUserInfoResponse>> {
    return await AuthHelper.fetch<Array<IUserInfoResponse>>('/api/v1/users/search_with_be_id', {
      method: 'POST',
      params: { q },
      body: {
        sub: useUserStore()?.userInfo?.sub,
        roles: [
          EUserGroup.ClientErp,
          EUserGroup.ClientMpErp,
          EUserGroup.ClientAdmin,
          EUserGroup.ClientMpSupervisor,
        ],
        beId,
      },
    });
  }

  public static async getClientBEInfo(beId: number) {
    return await AuthHelper.fetch(`/api/v1/client/business_entity/${beId}`, { method: 'GET', params: { mpOrdersAllowed: true } }, true);
  }

  public static async getSubdivisionsList(beId: number): Promise<ISubdivisionInfoResponse> {
    return await AuthHelper.fetch<ISubdivisionInfoResponse>(
      `/api/v1/business_entities/client_subdivision/subdivisions_list/${beId}`,
      {method: 'GET'},
      true,
    );
  }

  public static async getPFMList(): Promise<Array<IClientPFMTableItem>> {
    return await AuthHelper.fetch('/api/v1/business_entities/pfms', { method: 'GET' });
  }

  // open - доступен не только для админа, но и для фин. контролера
  public static async getAdminBusinessEntitiesWithSubdivisionsOpen(): Promise<Array<IUserBusinessEntities>> {
    return await AuthHelper.fetch('/api/v1/client/business_entity/by_sub_open/', {
      method: 'GET',
    });
  }

  public static async createClientPFM(pfm: ICreateClientPFM): Promise<void> {
    return await AuthHelper.fetch('/api/v1/business_entities/pfm/no_ext/', {
      method: 'POST',
      body: pfm,
    });
  }

  public static async editClientPFM(pfm: IEditClientPFM): Promise<void> {
    return await AuthHelper.fetch('/api/v1/business_entities/pfm/no_ext/', {
      method: 'PUT',
      body: pfm,
    });
  }

  public static async addSubdivision(beId: number, name: string, clientId: number): Promise<void> {
    return await AuthHelper.fetch('/api/v1/business_entities/client_subdivision/', {
      method: 'POST',
      body: {
        beId,
        name,
        clientId,
      },
    });
  }

  public static async deleteSubdivision(id: number, beId: number): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/business_entities/client_subdivision/${id}/`, {
      method: 'DELETE',
      params: { beId },
    });
  }

  public static async editSubdivisions(id: number, name: string, beId: number): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/business_entities/client_subdivision/${id}/`, {
      method: 'PATCH',
      body: {
        beId,
        name,
      },
    });
  }

  public static async getAdminBusinessEntitiesWithSubdivisions(): Promise<Array<IUserBusinessEntities>> {
    return await AuthHelper.fetch('/api/v1/client/business_entity/by_sub/', {
      method: 'GET',
    });
  }

  public static async getEmployeesList(q: string, page: number, pageSize: number): Promise<IEmployeesList> {
    return await AuthHelper.fetch<IEmployeesList>('/api/v1/client/employees/list',
      { method: 'GET', params: { q, page, pageSize } },
    );
  }

  public static async deleteEmployee(sub: string): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/client/employees/${sub}/`, {
      method: 'DELETE',
    });
  }

  public static async changeEmployeeStatus(sub: string, isActiveStatus: boolean): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/client/employees/${sub}/status/`, {
      method: 'PATCH',
      params: { isActiveStatus },
    });
  }

  public static async sendPasswordEmployee(sub: string): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/client/employees/${sub}/password_creation_link/`, {
      method: 'POST',
    });
  }

  public static async getEmployeeInfo(employeeSub: string): Promise<IAddEmployee> {
    return await AuthHelper.fetch<IAddEmployee>(`/api/v1/client/employees/${employeeSub}/`, { method: 'GET' });
  }

  public static async addEmployee(employeeData: IAddEmployee): Promise<void> {
    return await AuthHelper.fetch('/api/v1/client/employees/', { body: employeeData, method: 'POST' });
  }

  public static async editEmployee(employeeData: IAddEmployee): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/client/employees/${employeeData?.sub}/`, { body: employeeData, method: 'PUT' });
  }

  public static async deleteClientPFM(pfmId: number): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/business_entities/pfm/no_ext/${pfmId}/`, {
      method: 'DELETE',
    });
  }

  public static async getPFMById(pfmId: number): Promise<IClientPFMItem> {
    return await AuthHelper.fetch(`/api/v1/business_entities/pfm/${pfmId}/`, {
      method: 'GET',
    });
  }

  public static async getAllClients(withBusinessEntities = false): Promise<Array<IClientShortInfo>> {
    return await AuthHelper.fetch('/api/v1/clients/all', {
      method: 'GET',
      params: {
        withBusinessEntities,
      },
    });
  }

  public static async getBusinessEntitiesByUserV2(clientId: number, allBe: boolean): Promise<Array<IBusinessEntityByUser>> {
    return AuthHelper.fetch(`/api/v2/clients/${clientId}/business-entities/select-by-user-id`, {
      method: 'POST',
      body: {
        allBe,
      },
    });
  }
}
