import {
  IAddErpProduct,
  IBasket,
  IErpBasketActualizationErrorResponse,
  IErpBasketBuildStatusResponse,
  IErpBasketCategory,
  IErpBasketDistributionResponse,
  IErpSubBasket,
  IPlaceErpBasketData,
  ISaveBasketResponse,
  IUpdateErpBasketProductData,
  IValidateChanges,
  TErpBasket,
} from 'models/basket.model';
import { EBasketType } from 'enums/basket/basketType.enum';
import { EProductTags } from 'shared/enums/productTags.enum';
import { AuthHelper } from 'utils/authHelper.util';
import { EERpBasketDistributionStatus } from 'enums/basket/erpBasketDistributionStatus.enum';
import { IReport } from 'models/client/report.model';
import { IDistributionStatusResponse } from 'models/basket.model';

export class BasketErpApi {
  private static readonly _basePath = '/api/v1/orders/erp';
  private static readonly _itemsPath = `${this._basePath}/items`;
  private static readonly _needsPath = `${this._basePath}/needs`;
  private static readonly _baseSubPath = `${this._basePath}/sub`;
  private static readonly _ordersPathV2 = '/api/v2/orders';
  private static readonly _subordersPathV2 = `${this._ordersPathV2}/erp-editing/suborders`;

  private static readonly _basePathV2 = '/api/v2/carts/erp';

  public static async getBasket(orderId: number, type: EBasketType, needSort?: boolean): Promise<IBasket<IErpBasketCategory>> {
    return await AuthHelper.fetch<IBasket<IErpBasketCategory>>(`${this._basePath}/${orderId}`, {
      method: 'GET',
      params: { order_type: type, filterSpecificationsTagsBy: EProductTags.ListingTile, needSort },
      cache: 'no-cache',
    });
  }

  public static async getBasketBuildCompletedStatus(orderId: number): Promise<IErpBasketBuildStatusResponse> {
    return await AuthHelper.fetch<IErpBasketBuildStatusResponse>(`${this._basePath}/${orderId}/order_status`, {
      method: 'GET',
      cache: 'no-cache',
    });
  }

  public static async getErpNeedsInfo(orderId: number, code: number, itemIds: Array<number>, isGroupNeed?: boolean) {
    return await AuthHelper.fetch('/api/v1/client/orders/erp_needs', {
      method: 'POST',
      body: { orderId, code, itemIds, isGroupNeed },
    });
  }

  public static async addProduct(needId: number, body: IAddErpProduct): Promise<IBasket<IErpBasketCategory>> {
    return await AuthHelper.fetch(`${this._itemsPath}/${needId}`, {
      method: 'POST',
      body,
      params: {
        filterSpecificationsTagsBy: EProductTags.ListingTile,
      },
    });
  }

  public static async updateProduct(id: number, body: IUpdateErpBasketProductData): Promise<IBasket<IErpBasketCategory>> {
    return await AuthHelper.fetch(`${this._itemsPath}/${id}`, {
      method: 'PATCH',
      body,
      params: {
        filterSpecificationsTagsBy: EProductTags.ListingTile,
      },
    });
  }

  public static async deleteBasket(id: number): Promise<void> {
    return await AuthHelper.fetch(`${this._basePath}/${id}`, {
      method: 'DELETE',
    });
  }

  public static async deleteProduct(id: number): Promise<IBasket<IErpBasketCategory>> {
    return await AuthHelper.fetch(`${this._itemsPath}/${id}`, {
      method: 'DELETE',
      params: {
        filterSpecificationsTagsBy: EProductTags.ListingTile,
      },
    });
  }

  public static async deleteNeed(id: number): Promise<IBasket<IErpBasketCategory>> {
    return await AuthHelper.fetch(`${this._needsPath}/${id}`, {
      method: 'DELETE',
      params: {
        filterSpecificationsTagsBy: EProductTags.ListingTile,
      },
    });
  }

  public static async getNeed(id: number): Promise<void> {
    return await AuthHelper.fetch(`${this._needsPath}/${id}`, {
      method: 'GET',
    });
  }

  public static async getNeedsInfo(id: number, mtp: number) {
    return await AuthHelper.fetch(`/api/v1/orders/erp/needs/${id}/${mtp}`, { method: 'GET' });
  }

  public static async restoreNeed(id: number): Promise<IBasket<IErpBasketCategory>> {
    return await AuthHelper.fetch(`${this._needsPath}/${id}`, {
      method: 'PATCH',
      params: {
        filterSpecificationsTagsBy: EProductTags.ListingTile,
      },
    });
  }

  public static async placeOrder(id: number, body: IPlaceErpBasketData): Promise<ISaveBasketResponse> {
    return await AuthHelper.fetch<ISaveBasketResponse>(`${this._basePath}/${id}/place_order`, {
      method: 'POST',
      body,
    });
  }

  public static async replaceProduct(productId: number, needId: number, body: IAddErpProduct): Promise<IBasket<IErpBasketCategory>> {
    return await AuthHelper.fetch<IBasket<IErpBasketCategory>>(`${this._itemsPath}/${needId}/${productId}`, {
      method: 'PUT',
      params: { filterSpecificationsTagsBy: EProductTags.ListingTile },
      body,
    });
  }

  public static async getSubOrder(supplierOrderId: number, type: EBasketType, needSort?: boolean): Promise<IErpSubBasket> {
    return await AuthHelper.fetch<IErpSubBasket>(`${this._baseSubPath}/${supplierOrderId}`, {
      method: 'GET',
      params: { order_type: type, filterSpecificationsTagsBy: EProductTags.ListingTile, needSort },
      cache: 'no-cache',
    });
  }

  public static async validateChanges(suborderId: number): Promise<boolean> {
    const response = await AuthHelper.fetch<IValidateChanges>(`${this._subordersPathV2}/${suborderId}/validate_changes`, {
      method: 'GET',
    });
    return response.hasNotChangedOffers;
  }

  public static async placeSubOrder(
    supplierOrderId: number,
    suborderDiffCheck: boolean,
    body: IPlaceErpBasketData,
  ): Promise<ISaveBasketResponse> {
    return await AuthHelper.fetch<ISaveBasketResponse>(`${this._baseSubPath}/${supplierOrderId}/place_order`, {
      method: 'POST',
      params: { suborderDiffCheck },
      body,
    });
  }

  public static async getActualizationError(requestId: string): Promise<IErpBasketActualizationErrorResponse> {
    return await AuthHelper.fetch<IErpBasketActualizationErrorResponse>(`${this._basePath}/actualization_error/${requestId}/`, {
      method: 'GET',
    });
  }

  public static async redistributeOrder(orderId: number, partialCoverageDistributionStatus?: EERpBasketDistributionStatus): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/order/redistribute/${orderId}`, {
      method: 'POST',
      body: { partialCoverageDistributionStatus },
    });
  }

  public static async getErpDistribution(orderId: number): Promise<IErpBasketDistributionResponse> {
    return await AuthHelper.fetch(`${this._basePath}/${orderId}/erp_distribution`);
  }

  public static async updateErpDistribution(
    orderId: number,
    erpDistribution: EERpBasketDistributionStatus,
  ): Promise<IErpBasketDistributionResponse> {
    return await AuthHelper.fetch(`${this._basePath}/${orderId}/erp_distribution`, {
      method: 'patch',
      body: {
        erpDistribution,
      },
    });
  }

  public static async updateDistribution(
    orderId: number,
    distribution: EERpBasketDistributionStatus,
  ): Promise<IDistributionStatusResponse> {
    return await AuthHelper.fetch(`/api/v2/carts/${orderId}/distribution`, {
      method: 'patch',
      body: { distribution },
    });
  }

  public static async getErpOrderReportById(orderId: number): Promise<IReport> {
    return await AuthHelper.fetch(`${this._basePath}/${orderId}/report`);
  }

  public static getBasketV2(basketId: number, sortNeeds = false): Promise<TErpBasket> {
    return AuthHelper.fetch(`${this._basePathV2}/${basketId}`, {
      method: 'GET',
      params: { sortNeeds },
    });
  }

  public static deleteBasketV2(basketId: number): Promise<void> {
    return AuthHelper.fetch(`${this._basePathV2}/${basketId}`, {
      method: 'DELETE',
    });
  }

  public static addItemV2(basketId: number, needId: number, body: IAddErpProduct): Promise<TErpBasket> {
    return AuthHelper.fetch(`${this._basePathV2}/${basketId}/needs/${needId}`, {
      method: 'POST',
      body,
    });
  }

  public static replaceItemV2(basketId: number, needId: number, itemId: number, body: IAddErpProduct): Promise<TErpBasket> {
    return AuthHelper.fetch(`${this._basePathV2}/${basketId}/needs/${needId}/items/${itemId}`, {
      method: 'PUT',
      body,
    });
  }

  public static deleteNeedV2(basketId: number, needId: number): Promise<TErpBasket> {
    return AuthHelper.fetch(`${this._basePathV2}/${basketId}/needs/${needId}`, {
      method: 'DELETE',
    });
  }

  public static restoreNeedV2(basketId: number, needId: number): Promise<TErpBasket> {
    return AuthHelper.fetch(`${this._basePathV2}/${basketId}/needs/${needId}`, {
      method: 'PATCH',
    });
  }

  public static deleteItemV2(basketId: number, itemId: number): Promise<TErpBasket> {
    return AuthHelper.fetch(`${this._basePathV2}/${basketId}/items/${itemId}`, {
      method: 'DELETE',
    });
  }

  public static updateItemV2(basketId: number, itemId: number, quantity: number): Promise<TErpBasket> {
    return AuthHelper.fetch(`${this._basePathV2}/${basketId}/items/${itemId}`, {
      method: 'PATCH',
      body: { quantity },
    });
  }

  public static placeBasketV2(basketId: number, body: IPlaceErpBasketData): Promise<ISaveBasketResponse> {
    return AuthHelper.fetch(`${this._basePathV2}/${basketId}/place`, {
      method: 'POST',
      body,
    });
  }

  public static getSubOrderV2(suborderId: number, needSort?: boolean): Promise<IErpSubBasket> {
    return AuthHelper.fetch(`${this._subordersPathV2}/${suborderId}/move`, {
      method: 'POST',
      params: {
        needSort,
      },
    });
  }

  public static placeSubOrderV2(suborderId: number, suborderDiffCheck: boolean): Promise<unknown> {
    return AuthHelper.fetch(`${this._subordersPathV2}/${suborderId}/place`, {
      method: 'POST',
      params: { suborderDiffCheck },
    });
  }
}
