import { EUserGroup } from 'shared/enums/user/userGroup.enum';
import { useUserStore } from 'store/user.store';

export function useSearchGroupAccess() {
  const userStore = useUserStore();

  return computed(
    () => userStore.isUserHasGroup([
      EUserGroup.ClientBoss,
      EUserGroup.ClientAdmin,
      EUserGroup.ClientMpSupervisor,
      EUserGroup.ClientErp,
      EUserGroup.ClientMpErp,
      EUserGroup.ClientFinContr,
      EUserGroup.ClientAuditor,
      EUserGroup.ClientCategoryManager,
    ]),
  );
}
