/**
 * Функция для обработки асинхронных функций пулом. Limit отвечает за одновременное выполнение
 * параллельных асинхронных функций.
 * На вход принимает список асинхронных функций.
 * Пример: promiseAllLimit(someArray.map(someValue => async () => { ...Какая то асинхронная логика }), 5);
 */
export async function promiseAllLimit<T>(list: Array<() => Promise<T>>, limit = 5): Promise<Array<T>> {
  const head = list.slice(0, limit);
  const tail = list.slice(limit);
  const result: Array<T> = [];

  const execute = async (promise: () => Promise<T>, i: number, runNext: () => Promise<void>) => {
    result[i] = await promise();
    await runNext();
  };

  const runNext = async () => {
    const i = list.length - tail.length;
    const promise = tail.shift();
    if (promise !== undefined) {
      await execute(promise, i, runNext);
    }
  };

  await Promise.all(head.map((promise, i) => execute(promise, i, runNext)));

  return result;
}
